.track {
  width: 100%;
  height: auto;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  border-radius: 5px;
}

.projectCard {
  background-color: #000;
  width: 40vh;
  height: 30vh;
  min-width: 40vh;
  min-height: 30vh;
  margin: 1.5vh;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 20px;
  z-index: 1;
}

.projectCard img {
  width: 100%;
  height: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 0;
}

.projectCard h4 {
  width: 100%;
  height: auto;
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  text-decoration: none;
  margin: 1.5vh auto;
  color: #f9f9f6;
}

@media (max-width: 770px) {
  .track {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .projectCard {
    margin: 1.5vh auto;
  }
}

@media (max-width: 466px) {
  .projectCard h4 {
    font-size: 0.9rem;
  }
}

@media (max-width: 400px){
  #title {
    font-size: 1.8rem;
  }
  p {
    font-size: 1rem;
  }
}

@media (max-width: 280px) {
  .projectCard h4 {
    font-size: 0.7rem;
  }
}

@media (max-height: 700px) {
  .projectCard {
    width: 50%;
    height: 30%;
    margin: 1.5vh 5vh;
  }
}

@media (max-height: 400px) {
  .projectCard {
    width: 100vh;
    height: 70vh;
  }
  .projectCard h4 {
    font-size: 0.9rem;
  }
}

@media (max-height: 276px) {
  .projectCard {
      width: 150vh;
      height: 100vh;
    }
  .projectCard h4 {
    font-size: 0.7rem;
  }
}

