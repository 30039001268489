.header {
    background-image: url('../../assets/showcase.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 32rem;
}

.showcase {
    /* background-color: black; */
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 9rem 0 0 9rem;
    color: #000;
    text-transform: capitalize;
    text-align: left;
    font-size: 2rem;
    line-height: 1.3;
}   

@media (max-width: 1310px){
    .showcase {
        text-align: center;
        padding: 5rem 0 0 0;
    }
}

@media (max-width: 820px){
    .showcase {
        font-size: 2rem;
        text-align: center;
        padding: 5rem 0 0 0;

    }
}

@media (max-width: 560px){
    .header {
        height: 20rem;
    }
    .showcase {
        font-size: 1.5rem;
        padding: 6rem 0 0 0;
        text-align: center;
    }
}

@media (max-width: 400px){
    .showcase{
        font-size: 1rem;
        padding: 7rem 0 0 0;
    }
}