.about {
    width: 100%;
    height: auto;
    padding: 3vh;
    background-color: #FFFAFA;
    color: #000;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    text-transform: ;
}

.about h2 {
    font-size: 2.8rem;
    text-transform: capitalize;
    margin: 1vh;
}

.aboutInfo {
    width: 100%;
    height: auto;
    padding: 1vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    line-height: 30px;
}

.about p {
    max-width: 72%;
    height: auto;
    margin: 1vh;
    font-size: 1.1rem;
    font-weight: bold;
}

.aboutInfo a{
    color: #000;
}

.imgContainer {
    width: 25vh;
    height: 25vh;
    margin: 1vh;
}

.imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border: none;
    border-radius: 60%;
}

.aboutInfo h3 {
    text-transform: capitalize;
}

.techListContainer {
    width: 72%;
    height: auto;
    padding: 1vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.techListContainer li {
    margin: 1vh;
}

.techList {
    max-width: 100%;
    height: 11vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 1vh auto;
}

@media (max-width: 750px) {
    .aboutInfo p {
        max-width: 100%;
        font-size: 1rem;
    }
}

@media (max-width: 400px) {
    .about h2{
        font-size: 1.8rem;
    }
    .aboutInfo p{
        font-size: 0.9rem;
        width: 100%;
    }
    .aboutInfo {
        width: 100%;
        font-size: 0.9rem;
    }
    .techListContainer {
        width: 100%;
    }
    .techList {
        width: 100%;
    }
}

@media (max-height: 470px) {
    .about {
    }
    .about h2 {
        font-size: 1.5rem;
    }
    .about p {
        font-size: 0.8rem;
        width: 400px;
        height: auto;
    }
    .techListContainer {
        width: 400px;
        height: auto;
        flex-flow: column;
    }
    .techListContainer li {
        width: 80%;
        margin: 3vh;
    }
    .techListContainer .techList {
        flex-flow: row;
        margin: 0 auto;
        width: 60%;
    }
    .techListContainer li h3 {
        font-size: 0.9rem;
    }
        .techListContainer li i {
            font-size: 1.2rem;
        }

    .imgContainer {
        margin: 5vh;
    }
}
