.projects {
    background-color: #FFFAFA;
    width: 100%;
    height: auto;
    padding-top: 3vh;
    margin: 0;
    text-align: center;
    color: #000;
}

.projects h2 {
    margin: 1vh;
    font-size: 2.8rem;
    text-transform: capitalize;
}

.listContainer {
    width: 90%;
    height: auto;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    align-items: center;
}

.listContainer h3 {
    font-size: 1.8rem;
    text-transform: capitalize;
    margin: 2vh;

}

@media (max-width: 1250px){
    .listContainer {
        width: 98%;
    }
}

@media (max-width: 1030px){
    .listContainer {
        text-align: center;
    }
}

@media (max-width: 400px) {
    .projects h2 {
        font-size: 1.8rem;
    }
}

