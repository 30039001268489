.icons {
    background-color: #000;
    color: #FFFAFA;
    width: 100%;
    height: 20vh;
    margin: 0 auto;
    /* padding: 2vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}

.icons h2 {
    font-size: 2.4rem;
}

.icons ul {
    width: 40%;
    height: 3rem;
    margin: 1vh auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.icons ul li {
    margin: 4vh;
    cursor: pointer;
}

.icons ul li a {
    color: #FFFAFA;
}

@media (max-width: 800px) {
    .icons,
    .icons ul {
        font-size: 0.9rem;
    }

    .icons h2 {
        font-size: 2.1rem;
    }
}

@media (max-width: 400px) {
    .icons h2 {
        font-size: 1.8rem;
    }
    .icons li i {
        font-size: 1.5rem;
    }
}

@media (max-height: 470px){
    .icons {
        min-height: 30vh;
    }
}

@media (max-height: 200px) {
    .icons h2 {
        margin-top: 1.2vh;
        font-size: 1rem;
    }
    .icons li i {
        font-size: 0.8rem;
    }
}