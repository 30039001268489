@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: black;
  font-family: 'Montserrat', sans-serif;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

.showcase {
    background-image: url(/static/media/showcase.0a38cc23.jpg);
    background-position: center ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    padding-top: 9rem;
    color: #000;
    text-transform: capitalize;
    text-align: center;
    font-size: 2rem;
}
.Header_header__1VCKf {
    background-image: url(/static/media/showcase.0a38cc23.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 32rem;
}

.Header_showcase__2KTlY {
    /* background-color: black; */
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 9rem 0 0 9rem;
    color: #000;
    text-transform: capitalize;
    text-align: left;
    font-size: 2rem;
    line-height: 1.3;
}   

@media (max-width: 1310px){
    .Header_showcase__2KTlY {
        text-align: center;
        padding: 5rem 0 0 0;
    }
}

@media (max-width: 820px){
    .Header_showcase__2KTlY {
        font-size: 2rem;
        text-align: center;
        padding: 5rem 0 0 0;

    }
}

@media (max-width: 560px){
    .Header_header__1VCKf {
        height: 20rem;
    }
    .Header_showcase__2KTlY {
        font-size: 1.5rem;
        padding: 6rem 0 0 0;
        text-align: center;
    }
}

@media (max-width: 400px){
    .Header_showcase__2KTlY{
        font-size: 1rem;
        padding: 7rem 0 0 0;
    }
}
.Projects_projects__1Wgds {
    background-color: #FFFAFA;
    width: 100%;
    height: auto;
    padding-top: 3vh;
    margin: 0;
    text-align: center;
    color: #000;
}

.Projects_projects__1Wgds h2 {
    margin: 1vh;
    font-size: 2.8rem;
    text-transform: capitalize;
}

.Projects_listContainer__3FSV8 {
    width: 90%;
    height: auto;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    align-items: center;
}

.Projects_listContainer__3FSV8 h3 {
    font-size: 1.8rem;
    text-transform: capitalize;
    margin: 2vh;

}

@media (max-width: 1250px){
    .Projects_listContainer__3FSV8 {
        width: 98%;
    }
}

@media (max-width: 1030px){
    .Projects_listContainer__3FSV8 {
        text-align: center;
    }
}

@media (max-width: 400px) {
    .Projects_projects__1Wgds h2 {
        font-size: 1.8rem;
    }
}


.ProjectsList_track__T9y_z {
  width: 100%;
  height: auto;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  border-radius: 5px;
}

.ProjectsList_projectCard__1KLD_ {
  background-color: #000;
  width: 40vh;
  height: 30vh;
  min-width: 40vh;
  min-height: 30vh;
  margin: 1.5vh;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 20px;
  z-index: 1;
}

.ProjectsList_projectCard__1KLD_ img {
  width: 100%;
  height: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 0;
}

.ProjectsList_projectCard__1KLD_ h4 {
  width: 100%;
  height: auto;
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  text-decoration: none;
  margin: 1.5vh auto;
  color: #f9f9f6;
}

@media (max-width: 770px) {
  .ProjectsList_track__T9y_z {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ProjectsList_projectCard__1KLD_ {
    margin: 1.5vh auto;
  }
}

@media (max-width: 466px) {
  .ProjectsList_projectCard__1KLD_ h4 {
    font-size: 0.9rem;
  }
}

@media (max-width: 400px){
  #ProjectsList_title__13ppw {
    font-size: 1.8rem;
  }
  p {
    font-size: 1rem;
  }
}

@media (max-width: 280px) {
  .ProjectsList_projectCard__1KLD_ h4 {
    font-size: 0.7rem;
  }
}

@media (max-height: 700px) {
  .ProjectsList_projectCard__1KLD_ {
    width: 50%;
    height: 30%;
    margin: 1.5vh 5vh;
  }
}

@media (max-height: 400px) {
  .ProjectsList_projectCard__1KLD_ {
    width: 100vh;
    height: 70vh;
  }
  .ProjectsList_projectCard__1KLD_ h4 {
    font-size: 0.9rem;
  }
}

@media (max-height: 276px) {
  .ProjectsList_projectCard__1KLD_ {
      width: 150vh;
      height: 100vh;
    }
  .ProjectsList_projectCard__1KLD_ h4 {
    font-size: 0.7rem;
  }
}


.About_about__NonCt {
    width: 100%;
    height: auto;
    padding: 3vh;
    background-color: #FFFAFA;
    color: #000;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    text-transform: ;
}

.About_about__NonCt h2 {
    font-size: 2.8rem;
    text-transform: capitalize;
    margin: 1vh;
}

.About_aboutInfo__1dvw2 {
    width: 100%;
    height: auto;
    padding: 1vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    line-height: 30px;
}

.About_about__NonCt p {
    max-width: 72%;
    height: auto;
    margin: 1vh;
    font-size: 1.1rem;
    font-weight: bold;
}

.About_aboutInfo__1dvw2 a{
    color: #000;
}

.About_imgContainer__33_ga {
    width: 25vh;
    height: 25vh;
    margin: 1vh;
}

.About_imgContainer__33_ga img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border: none;
    border-radius: 60%;
}

.About_aboutInfo__1dvw2 h3 {
    text-transform: capitalize;
}

.About_techListContainer__2seoA {
    width: 72%;
    height: auto;
    padding: 1vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.About_techListContainer__2seoA li {
    margin: 1vh;
}

.About_techList__hzSTG {
    max-width: 100%;
    height: 11vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 1vh auto;
}

@media (max-width: 750px) {
    .About_aboutInfo__1dvw2 p {
        max-width: 100%;
        font-size: 1rem;
    }
}

@media (max-width: 400px) {
    .About_about__NonCt h2{
        font-size: 1.8rem;
    }
    .About_aboutInfo__1dvw2 p{
        font-size: 0.9rem;
        width: 100%;
    }
    .About_aboutInfo__1dvw2 {
        width: 100%;
        font-size: 0.9rem;
    }
    .About_techListContainer__2seoA {
        width: 100%;
    }
    .About_techList__hzSTG {
        width: 100%;
    }
}

@media (max-height: 470px) {
    .About_about__NonCt {
    }
    .About_about__NonCt h2 {
        font-size: 1.5rem;
    }
    .About_about__NonCt p {
        font-size: 0.8rem;
        width: 400px;
        height: auto;
    }
    .About_techListContainer__2seoA {
        width: 400px;
        height: auto;
        flex-flow: column;
    }
    .About_techListContainer__2seoA li {
        width: 80%;
        margin: 3vh;
    }
    .About_techListContainer__2seoA .About_techList__hzSTG {
        flex-flow: row;
        margin: 0 auto;
        width: 60%;
    }
    .About_techListContainer__2seoA li h3 {
        font-size: 0.9rem;
    }
        .About_techListContainer__2seoA li i {
            font-size: 1.2rem;
        }

    .About_imgContainer__33_ga {
        margin: 5vh;
    }
}

.Icons_icons__d3fqR {
    background-color: #000;
    color: #FFFAFA;
    width: 100%;
    height: 20vh;
    margin: 0 auto;
    /* padding: 2vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}

.Icons_icons__d3fqR h2 {
    font-size: 2.4rem;
}

.Icons_icons__d3fqR ul {
    width: 40%;
    height: 3rem;
    margin: 1vh auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Icons_icons__d3fqR ul li {
    margin: 4vh;
    cursor: pointer;
}

.Icons_icons__d3fqR ul li a {
    color: #FFFAFA;
}

@media (max-width: 800px) {
    .Icons_icons__d3fqR,
    .Icons_icons__d3fqR ul {
        font-size: 0.9rem;
    }

    .Icons_icons__d3fqR h2 {
        font-size: 2.1rem;
    }
}

@media (max-width: 400px) {
    .Icons_icons__d3fqR h2 {
        font-size: 1.8rem;
    }
    .Icons_icons__d3fqR li i {
        font-size: 1.5rem;
    }
}

@media (max-height: 470px){
    .Icons_icons__d3fqR {
        min-height: 30vh;
    }
}

@media (max-height: 200px) {
    .Icons_icons__d3fqR h2 {
        margin-top: 1.2vh;
        font-size: 1rem;
    }
    .Icons_icons__d3fqR li i {
        font-size: 0.8rem;
    }
}
